import { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { easeSlow, getMotionProperties } from "../../../utils/motionUtils";
import { useMediaQueries } from "../../../utils/mediaQueries";
import NavMenu from "./NavMenu";
import NavbarLink from "./NavbarLink";
import ButtonSecondary from "../Buttons/ButtonSecondary";
import styles from "./NavHome.module.css";

const navbarData = [
  { linkText: "Start here", linkURL: "#start_here" },
  { linkText: "For Whom", linkURL: "#for_whom" },
  { linkText: "For What", linkURL: "#for_what" },
  { linkText: "Showcases", linkURL: "#showcases" },
  { linkText: "Features", linkURL: "#features" },
  { linkText: "Pricing", linkURL: "#pricing" },
];

const Nav = ({ sectionZIndex, navBackgroundColor }) => {
  const { mq1024, mq430 } = useMediaQueries();

  const [navbarActive, setNavbarActive] = useState(false);
  const [logoURL, setLogoURL] = useState("/logoROSA.svg");

  useEffect(() => {
    const handleScroll = () => {
      if (mq430) {
        return;
      }

      const currentScrollY = window.scrollY;

      if (currentScrollY > 80 && !navbarActive) {
        setNavbarActive(true);
      } else if (currentScrollY <= 80 && navbarActive) {
        setNavbarActive(false);
      }

      setLogoURL(navbarActive ? "/logoROSADark.svg" : "/logoROSA.svg");
    };

    const debouncedScroll = debounce(handleScroll, 1);
    window.addEventListener("scroll", debouncedScroll);

    return () => {
      window.removeEventListener("scroll", debouncedScroll);
    };
  }, [navbarActive, mq430]);

  const [overlayOpen, setOverlayOpen] = useState(false);

  const toggleOverlay = () => {
    setOverlayOpen(!overlayOpen);
    document.body.style.overflow = overlayOpen ? "visible" : "hidden";
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    document.body.style.overflow = "visible";
  };

  const containerSectionStyle = useMemo(() => {
    return {
      zIndex: sectionZIndex,
      backgroundColor: navBackgroundColor,
    };
  }, [sectionZIndex, navBackgroundColor]);

  return (
    <>
      <motion.nav
        {...getMotionProperties("0, -40px", "0, 0", easeSlow)}
        className={`${styles.containerNav} ${
          navbarActive && styles.containerNavActive
        }`}
        style={containerSectionStyle}
      >
        <div className={styles.containerSection}>
          <a href="." className={styles.logoLink}>
            <img
              className={styles.imageLogo}
              alt="ROSA logotype"
              src={logoURL}
            />
          </a>
          {!mq1024 && (
            <div className={styles.containerContent}>
              {navbarData.map((data, index) => (
                <NavbarLink
                  key={index}
                  linkText={data.linkText}
                  linkURL={data.linkURL}
                  linkColor={
                    navbarActive
                      ? "var(--surface-secondary)"
                      : "var(--surface-primary)"
                  }
                />
              ))}
            </div>
          )}
          {!mq1024 && (
            <ButtonSecondary
              showButtonText={true}
              buttonSecondaryText="Get your app"
              buttonSecondaryLink="https://outlook.office365.com/owa/calendar/RulesofSportsAppROSAteam@mamf.pl/bookings/"
              radiusX="16"
              radiusY="4"
              radiusXHover="32"
              radiusYHover="8"
            />
          )}
        </div>
        {mq1024 && (
          <div
            className={`${styles.containerMenu} ${
              overlayOpen && styles.active
            }`}
            id="toggle"
            onClick={toggleOverlay}
          >
            <span className={styles.containerMenuTop}></span>
            <span className={styles.containerMenuMiddle}></span>
            <span className={styles.containerMenuBottom}></span>
          </div>
        )}
      </motion.nav>
      <NavMenu
        isOpen={overlayOpen}
        closeOverlay={closeOverlay}
        navbarData={navbarData}
      />
    </>
  );
};

export default Nav;

function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
