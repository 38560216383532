import React from "react";
import styles from "./Bullet.module.css";
import { radius } from "../../../../utils/radiusUtils";
import NavbarLink from "../../Navigation/NavbarLink";

const Bullet = ({ textBullet }) => {
  const isLink = textBullet.match(/(http[s]?:\/\/[^\s]+)/g);
  const isEmail = textBullet.match(
    /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
  );

  return (
    <div className={styles.containerContent}>
      <div
        className={styles.containerBullet}
        style={radius({ x: 4, y: 4 })}
      ></div>
      <p className={styles.textBullet}>
        {isLink ? (
          <NavbarLink
            linkURL={isLink[0]}
            linkText={textBullet}
            linkColor="var(--surface-secondary)"
          />
        ) : isEmail ? (
          <a href={`mailto:${isEmail[0]}`}>{textBullet}</a>
        ) : (
          textBullet
        )}
      </p>
    </div>
  );
};

export default Bullet;
