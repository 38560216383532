import React from "react";
import NavPages from "../components/Elements/Navigation/NavPages";
import ContentText from "../components/Elements/Contents/ContentText";
import NavPrivacyMenu from "../components/Elements/Navigation/NavPrivacyMenu";
import Footer from "../components/Sections/Footer";
import ContentPrivacyPolicy from "../components/Elements/Privacy Policy/ContentPrivacyPolicy";
import { motion } from "framer-motion";
import { easeFast, getMotionProperties } from "../utils/motionUtils";
import styles from "./PrivacyPolicy.module.css";

const motionContainer = getMotionProperties("0, 160px", "0, 0", easeFast);

const PrivacyPolicy = () => {
  return (
    <div className={styles.containerSection} id="start_here">
      <NavPages alt="ROSA logo" sectionZIndex="1001" />
      <motion.div {...motionContainer}>
        <div className={styles.containerContent}>
          <ContentText
            showTextHeaderLight={true}
            showTextHeaderBold={true}
            showTextHeaderLink={false}
            showTextParagraph={true}
            showTextBold={false}
            showButtonPrimary={false}
            showButtonSecondary={false}
            textHeaderLight="Privacy"
            textHeaderLightColor="var(--surface-secondary)"
            textHeaderLightAlign="left"
            textHeaderBold="Policy"
            textHeaderBoldColor="var(--surface-secondary)"
            textHeaderBoldAlign="left"
            textParagraph="Last updated: January 25, 2023 

          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. 
          
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator."
            textParagraphColor="var(--surface-secondary)"
            textParagraphMaxWidth="100%"
            containerWidth="100%"
            containerMaxWidth="1520px"
            containerFlexDirection="column"
            containerAlignItems="flex-start"
            containerJustifyContent="flex-start"
            containerGap="var(--size-48)"
            containerHeaderAlignItems="flex-start"
            containerHeaderJustifyContent="flex-start"
            containerHeaderFlexDirection="row"
          />

          <div className={styles.containerPage}>
            {/* <NavPrivacyMenu /> */}
            <div className={styles.containerList}>
              <ContentPrivacyPolicy />
            </div>
          </div>
        </div>
      </motion.div>
      <Footer showBottomContent={false} showHomeLink={true} />
    </div>
  );
};

export default PrivacyPolicy;
