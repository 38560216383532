import React from "react";
import { motion } from "framer-motion";
import { getMotionProperties, easeSlow, easeFast, getHoverProperties } from "../../utils/motionUtils";
import { useMediaQueries } from "../../utils/mediaQueries";
import ContentArticle from "../Elements/Contents/ContentArticle";
import ButtonScroll from "../Elements/Buttons/ButtonScroll";
import styles from "./StartHere.module.css";

const StartHere = ({ sectionZIndex }) => {
  const { mq1024 } = useMediaQueries();

  return (
    <section
      id="start_here"
      className={styles.containerSection}
      style={{ zIndex: sectionZIndex }}
    >
      <div className={styles.containerContent}>
        <ContentArticle
          buttonAppStore="/buttonAppStore.svg"
          buttonGooglePlay="/buttonGooglePlay.svg"
        />
        <motion.div
          {...getHoverProperties(1.1, "5deg", easeFast)}
          className={styles.containerMotion}
        >
          <motion.div
            {...getMotionProperties("0, 200px", "0, 0", easeSlow)}
            className={styles.containerImage}
          >
            <img className={styles.imageRight} alt="ROSA home screen app 1" src="/mockups/heroMockup-1.png" />
            <img className={styles.imageLeft} alt="ROSA home screen app 2" src="/mockups/heroMockup-2.png" />
          </motion.div>
        </motion.div>
      </div>
      {!mq1024 && (
        <motion.div
          {...getMotionProperties("0, 40px", "0, 0", easeSlow)}
          className={styles.containerScrollMotion}
        >
          <ButtonScroll
            linkScrollURL="#for_whom"
            imageScrollURL="./icons/scroll-down.svg"
            iconScrollURL="./icons/arrow.down.svg"
          />
        </motion.div>
      )}
    </section>
  );
};

export default StartHere;
