import { useMemo } from "react";
import { motion } from "framer-motion";
import { easeSlow, getMotionProperties } from "../../../utils/motionUtils";
import useMediaQuery from "../../../utils/useMediaQuery";
import ButtonSecondary from "../Buttons/ButtonSecondary";
import styles from "./NavPages.module.css";

const Nav = ({ sectionZIndex }) => {
  const tabletHorizontal = useMediaQuery("(max-width: 1024px)");

  const containerSectionStyle = useMemo(() => {
    return {
      zIndex: sectionZIndex,
    };
  }, [sectionZIndex]);

  const motionNav = getMotionProperties("0, -40px", "0, 0", easeSlow);

  return (
    <motion.nav
      {...motionNav}
      className={styles.containerNav}
      style={containerSectionStyle}
    >
      <div className={styles.containerSection}>
        <a
          href="."
          style={{
            height: tabletHorizontal ? "24px" : "32px",
          }}
        >
          <img
            className={styles.imageLogo}
            alt="ROSA logotype"
            src="/logoROSADark.svg"
          />
        </a>
        <ButtonSecondary
          showButtonText={true}
          buttonSecondaryText="Get your app"
          buttonSecondaryLink="https://outlook.office365.com/owa/calendar/RulesofSportsAppROSAteam@mamf.pl/bookings/"
          radiusX="16"
          radiusY="4"
          radiusXHover="32"
          radiusYHover="8"
        />
      </div>
    </motion.nav>
  );
};

export default Nav;
