import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  getMotionProperties,
  easeFast,
  easeSlow,
} from "../../utils/motionUtils";
import Divider from "../Elements/Other/Divider";
import ContextText from "../Elements/Contents/ContentText";
import NavFooter from "../Elements/Navigation/NavFooter";
import ButtonScroll from "../Elements/Buttons/ButtonScroll";
import NavbarLink from "../Elements/Navigation/NavbarLink";

import styles from "./Footer.module.css";
import { useMediaQueries } from "../../utils/mediaQueries";

const navFooterData = [
  {
    links: [
      { show: true, linkText: "Start here", linkURL: "#start_here" },
      { show: true, linkText: "For Whom", linkURL: "#for_whom" },
      { show: true, linkText: "For What", linkURL: "#for_what" },
      { show: true, linkText: "Showcases", linkURL: "#showcases" },
      { show: true, linkText: "Features", linkURL: "#features" },
      { show: true, linkText: "Pricing", linkURL: "#pricing" },
    ],
  },
];

const Footer = ({ showBottomContent, showHomeLink }) => {
  const { mq768 } = useMediaQueries();

  return (
    <motion.footer
      {...getMotionProperties("0, 160px", "0, 0", easeFast)}
      className={styles.containerSection}
    >
      <div className={styles.containerContent}>
        <motion.header
          {...getMotionProperties("0, 40px", "0, 0", easeFast)}
          className={styles.containerHeader}
        >
          {mq768 && (
            <ContextText
              containerAlignItems="center"
              containerJustifyContent="center"
              containerHeaderAlignItems="center"
              containerHeaderJustifyContent="center"
              sectionWidth="100%"
              showTextHeaderLight={true}
              textHeaderLight="Get your own!"
              textHeaderLightSize="var(--size-34)"
              textHeaderLightColor="var(--surface-primary)"
              textHeaderLightWeight="var(--font-thin)"
              textHeaderLightAlign="center"
              showtextHeaderBold={false}
              showTextHeaderLink={true}
              textHeaderLink="hello@rosa.zone"
              textHeaderLinkSize="var(--size-34)"
              textHeaderLinkColor="var(--surface-primary)"
              textHeaderLinkWeight="var(--font-semibold)"
              textHeaderBoldAlign="center"
              textHeaderLinkURL="mailto:hello@rosa.zone"
            />
          )}
          {!mq768 && (
            <ContextText
              showTextHeaderLight={true}
              textHeaderLight="Get your own!"
              textHeaderLightColor="var(--surface-primary)"
              textHeaderLightWeight="var(--font-thin)"
              showtextHeaderBold={false}
              showTextHeaderLink={true}
              textHeaderLink="hello@rosa.zone"
              textHeaderLinkColor="var(--surface-primary)"
              textHeaderLinkWeight="var(--font-semibold)"
              textHeaderLinkURL="mailto:hello@rosa.zone"
            />
          )}
          {!mq768 && (
            <ButtonScroll
              linkScrollURL="#start_here"
              imageScrollURL="./icons/scroll-up.svg"
              iconScrollURL="./icons/arrow.up.svg"
            />
          )}
        </motion.header>

        <motion.div
          {...getMotionProperties("0, 40px", "0, 0", easeFast)}
          className={styles.containerBottom}
        >
          <div className={styles.containerLinks}>
            {showBottomContent &&
              navFooterData.map((data, index) => (
                <NavFooter key={index} {...data} />
              ))}

            {showHomeLink && <NavbarLink linkURL="/." linkText="Home" />}
          </div>
        </motion.div>
        <motion.div
          {...getMotionProperties("0, 40px", "0, 0", easeFast)}
          className={styles.containerCopyright}
        >
          <Divider dividerBorder="1px solid var(--on-surface-accent-disabled-dark)" />
          <div className={styles.containerCopyrightContent}>
            <p className={styles.textParagraph}>
              ©2024 ROSA All rights reserved
            </p>
            <div className={styles.containerCopyrightLinks}>
              <NavbarLink linkURL="https://mamf.pl" linkText="me&my Friends" />
              <NavbarLink linkURL="/privacy-policy" linkText="Privacy Policy" />
            </div>
          </div>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default Footer;
