import NavHome from "../components/Elements/Navigation/NavHome";
import SectionStartHere from "../components/Sections/StartHere";
import SectionForWhom from "../components/Sections/ForWhom";
import SectionForWhat from "../components/Sections/ForWhat";
import SectionShowcase from "../components/Sections/Showcase";
import SectionFeatures from "../components/Sections/Features";
import SectionPricing from "../components/Sections/Pricing";
import SectionFooter from "../components/Sections/Footer";
import { useMediaQueries } from "../utils/mediaQueries";
import styles from "./Home.module.css";

const Home = () => {
  const { mq1536, mq1280, mq1024, mq820, mq430 } = useMediaQueries();
  const sectionsData = [
    {
      component: SectionStartHere,
      sectionZIndex: "7",
    },
    {
      component: SectionForWhom,
      sectionZIndex: "6",
    },
    {
      component: SectionForWhat,
      sectionZIndex: "5",
    },
    {
      component: SectionShowcase,
      sectionID: "showcases",
      showContentText: true,
      showArticleSummary: false,
      showArticleTestimonial: true,
      sectionZIndex: "4",
      imageLeftAltText: "Showcase Mockup 1",
      imageLeftURL: "./mockups/showcaseMockup-1.png",
      imageRightAltText: "Showcase Mockup 2",
      imageRightURL: "./mockups/showcaseMockup-2.png",
      buttonAppStore: "/buttonAppStore.svg",
      buttonGooglePlay: "/buttonGooglePlay.svg",
      buttonGooglePlayLink:
        "https://play.google.com/store/apps/details?id=com.theifab.footballrules",
      buttonAppStoreLink:
        "https://apps.apple.com/us/app/football-rules-by-the-ifab/id6450178840?uo=2",
      containerPaddingBottom: mq430
        ? "var(--size-24)"
        : mq1536
        ? "var(--size-48)"
        : "var(--size-64)",
    },
    {
      component: SectionFeatures,
      sectionZIndex: "3",
    },
    {
      component: SectionPricing,
      sectionZIndex: "2",
    },
    {
      component: SectionShowcase,
      sectionID: "summary",
      showContentText: false,
      showArticle: true,
      showArticleTestimonial: false,
      sectionZIndex: "1",
      buttonAppStore: "/buttonAppStore.svg",
      buttonGooglePlay: "/buttonGooglePlay.svg",
      containerBackgroundImage: "url(/backgrounds/backgroundSummary.png)",
      imageLeftAltText: "",
      imageLeftURL: "./mockups/showcaseMockup-3.png",
      imageRightAltText: "",
      imageRightURL: "./mockups/showcaseMockup-4.png",
      buttonGooglePlayLink:
        "https://play.google.com/store/apps/details?id=pl.mamf.rosa.example&hl=pl&gl=US",
      buttonAppStoreLink:
        "https://apps.apple.com/pl/app/rosa-rules-of-sports-app/id1622562179",
      containerPaddingBottom: mq430
        ? "var(--size-176)"
        : mq1280
        ? "var(--size-216)"
        : "var(--size-256)",
    },
    {
      component: SectionFooter,
      showBottomContent: true,
    },
  ];

  const showcaseMarginTop =
    !mq820 &&
    sectionsData.some(
      (section) =>
        section.component === SectionShowcase && section.sectionID === "summary"
    )
      ? "var(--size-80)"
      : "var(--size-0)";

  return (
    <div className={styles.containerSection}>
      <NavHome alt="ROSA logo" sectionZIndex="1001" />
      {sectionsData.map((section, index) => {
        if (
          section.component === SectionShowcase &&
          section.sectionID === "summary"
        ) {
          return (
            <SectionShowcase
              key={index}
              {...section}
              sectionMarginTop={showcaseMarginTop}
            />
          );
        }
        const Component = section.component;
        return <Component key={index} {...section} />;
      })}
    </div>
  );
};

export default Home;
