import useMediaQuery from "../../../utils/useMediaQuery";
import styles from "./NavbarLinkMenu.module.css";

const NavbarLink = ({
  linkText = "Link Text",
  linkCursor,
  linkURL,
  linkColor,
}) => {
  const tabletHorizontal = useMediaQuery("(max-width: 1024px)");

  return (
    <div className={styles.linkState}>
      <a href={linkURL}>
        <div
          data-replace={linkText}
          className={styles.linkMobile}
          style={{ color: linkColor }}
        >
          <span>{linkText}</span>
        </div>
      </a>
    </div>
  );
};

export default NavbarLink;
